<template>
  <div>
    <v-row v-if="showMenu" class="hidden-md-and-up">
      <v-col class="text-right">
        <v-menu
          offset-y
          offset-x
          rounded
          nudge-left="100%"
          max-width="100%"
          max-height="100%"
        >
          <template v-slot:activator="{ on: menu }">
            <v-btn icon v-on="{ ...menu }">
              <v-icon medium class="mx-2">menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>
              <v-icon medium class="mx-2">fas fa-user</v-icon>
              {{ this.$store.state.user.personalInfo.email }}
            </v-subheader>
            <v-list-item
              v-if="$productName == PRODUCT_CODES.avcwise"
              @click="openContactUs()"
            >
              <v-list-item-icon class="mr-4">
                <v-icon medium class="mx-2"
                  >fa-solid fa-envelope-open-text</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>Contact Us</v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$productName !== PRODUCT_CODES.avcwise"
              @click="redirectToAccountSettings()"
            >
              <v-list-item-icon class="mr-4">
                <v-icon medium class="mx-2 icon-default-size"
                  >fas fa-cog</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>Settings</v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                $productName !== PRODUCT_CODES.avcwise &&
                  $productName !== PRODUCT_CODES.firefighter &&
                  $productName !== PRODUCT_CODES.psh &&
                  $productName !== PRODUCT_CODES.rm
              "
              @click="redirectToDocuments()"
            >
              <v-list-item-icon class="mr-4">
                <v-icon
                  medium
                  class="mx-2 icon-default-size"
                  :color="currentPage === 'documents' ? 'primary' : ''"
                  >fas fa-file-alt</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>Documents</v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$productName !== PRODUCT_CODES.avcwise"
              @click="
                shownoti = true;
                $store.state.notifications.newNotification &&
                  readNotification();
              "
            >
              <v-list-item-icon class="mr-4"
                ><v-icon medium class="mx-2 icon-default-size"
                  >fas fa-bell</v-icon
                >
                <i
                  v-if="$store.state.notifications.newNotification"
                  class="fas notif-red-bell mobile-red-dot"
                  >{{ $store.state.notifications.newNotification }}</i
                ></v-list-item-icon
              >
              <v-list-item-content>Notifications</v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$productName !== PRODUCT_CODES.avcwise"
              @click="
                $router.push('/help');
                callGAEvent($gtag, {
                  eventName: 'help_btn',
                  category: 'menu_click'
                });
              "
            >
              <v-list-item-icon class="mr-4">
                <v-icon medium class="mx-2 icon-default-size"
                  >fa fa-question-circle</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>Help / FAQ's</v-list-item-content>
            </v-list-item>

            <v-list-item @click="logoutUser()">
              <v-list-item-icon class="mr-4">
                <v-icon medium class="mx-2 icon-default-size"
                  >fas fa-power-off</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>Logout</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-if="shownoti"
      v-model="shownoti"
      class="hidden-md-and-up"
      style="position:fixed; top:0; right:0; overflow-y:scroll;"
      right
      temporary
      width="430px"
    >
      <v-list-item class="pl-4 bold-600">
        <v-list-item-content class="py-6">
          <v-list-item-title class="text-xl">
            Notifications
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon class="my-auto">
          <v-icon class="mx-2" medium @click="shownoti = false"
            >mdi-close-circle</v-icon
          >
        </v-list-item-icon>
      </v-list-item>

      <v-divider></v-divider>
      <v-list
        v-if="
          this.$store.state.notifications.notifications &&
            this.$store.state.notifications.notifications.length > 0
        "
      >
        <div
          v-for="(item, index) in this.$store.state.notifications.notifications"
          :key="index"
        >
          <v-list-item
            :id="item.id"
            class="notif-item"
            :class="item.isRead ? '' : 'new-notif'"
          >
            <v-list-item-content>{{ item.text }}</v-list-item-content>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-content
            >You don't have any notifications yet</v-list-item-content
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { callGAEvent } from '~/plugins/utils';
import { PRODUCT_CODES } from '~/constants';

export default {
  props: [
    'currentPage',
    'redirectToAccountSettings',
    'redirectToDocuments',
    'logoutUser',
    'readNotification',
    'showMenu'
  ],
  data() {
    return {
      shownoti: false,
      PRODUCT_CODES
    };
  },
  methods: {
    callGAEvent
  }
};
</script>
